@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
    overflow-x: hidden;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  ::-webkit-scrollbar {
    width: 0.7rem;
    border-radius: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: #50643c;
  }
  ::-webkit-scrollbar-thumb {
    background: #8DA276;
    border-radius: 0.25rem;
    transition: 0.2s;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ADC295;
    transition: 0.2s;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
